import {COLORS, newCOLORS, darken, hexToRgb} from 'core/styles';
import styled from 'styled-components';

const primary = {
    background: newCOLORS.primary300,
    color: '#fff',
    border: 'none',
    backgroundHover: newCOLORS.primary400,
    colorHover: '#fff',
};

const invert = {
    background: '#fff',
    color: newCOLORS.primary300,
    border: `1px solid ${newCOLORS.primary300}`,
    backgroundHover: newCOLORS.gray200,
    colorHover: newCOLORS.primary300,
};

const invertWarning = {
    background: '#fff',
    color: newCOLORS.warning,
    border: `1px solid ${newCOLORS.warning}`,
    backgroundHover: newCOLORS.warning,
    colorHover: '#fff',
};

const invertSuccess = {
    background: '#fff',
    color: newCOLORS.success,
    border: `1px solid ${newCOLORS.success}`,
    backgroundHover: newCOLORS.success,
    colorHover: '#fff',
};

const blank = {
    background: '#fff',
    color: newCOLORS.primary300,
    border: 'none',
    backgroundHover: newCOLORS.gray200,
    colorHover: newCOLORS.primary300,
    fontSize: '16px',
    lineHeight: '24px',
};

const disabled = {
    background: newCOLORS.gray200,
    color: newCOLORS.gray300,
    border: `1px solid ${newCOLORS.gray300}`,
    backgroundHover: newCOLORS.gray200,
    colorHover: newCOLORS.gray300,
};

export const themes = {
    primary,
    invert,
    invertWarning,
    invertSuccess,
    blank,
    disabled,
};

export const button = (props) => {
    const theme = props.disabled
        ? themes.disabled
        : themes[props.theme] || themes.primary;
    const style = props.style || {};

    return Object.assign(
        {
            background: theme.background,
            color: theme.color,
            border: theme.border,
            cursor: 'pointer',
            fontSize: theme.fontSize || '14px',
            lineHeight: theme.lineHeight || '20px',
            fontWeight: 'bold',
            display: 'block',
            borderRadius: '8px',
            padding: '8px 16px',
            textAlign: 'center',
            width: 'fit-content',
            height: 'fit-content',

            '&:active': {
                background: theme.backgroundHover,
                color: theme.colorHover,
            },
            '&:hover': {
                background: theme.backgroundHover,
                color: theme.colorHover,
            },

            '&[disabled]': {
                opacity: 0.5,
            },

            ...style,
        },
        props.style,
    );
};

export const StyledButton = styled.button`
    background: ${(props) => props.theme.background};
    color: ${(props) => props.theme.color};
    border: ${(props) => props.theme.border};
    font-size: ${(props) => props.theme.fontSize || '14px'};
    line-height: ${(props) => props.theme.lineHeight || '20px'};
    font-weight: 600;
    cursor: pointer;
    display: block;
    border-radius: 8px;
    padding: 8px 16px;
    text-align: center;
    width: fit-content;
    height: fit-content;

    &:active,
    &:hover {
        background: ${(props) => props.theme.backgroundHover};
        color: ${(props) => props.theme.colorHover};
    }

    &:disabled {
        background: ${disabled.background};
        color: ${disabled.color};
        border: ${disabled.border};
    }

    ${(props) => (props.$css ? props.$css : null)}
`;
