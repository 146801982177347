import React from 'react';
import propTypes from 'prop-types';

import {StyledButton, themes} from './styles';

const Button = (props) => {
    return (
        <StyledButton
            theme={themes[props.theme] || themes.primary}
            $css={props.$css}
            className={props.className}
            style={props.style}
            type={props.type || 'button'}
            disabled={props.disabled}
            onMouseUp={props.handleMouseUp}
            onClick={props.onClick}>
            {props.text}
            {props.children}
        </StyledButton>
    );
};

Button.propTypes = {
    type: propTypes.string,
    disabled: propTypes.bool,
    onMouseUp: propTypes.func,
    onClick: propTypes.func,
    text: propTypes.string,
    children: propTypes.node,
    theme: propTypes.oneOf([
        'primary',
        'invert',
        'invertWarning',
        'invertSuccess',
        'blank',
    ]),
};

export default Button;
